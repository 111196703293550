<template>
  <div class="actions">
    <!-- <router-link
        :to="{name: 'analyse_detail', params: {uid: object.uid}}"
        class="btn btn-secondary btn-sm"
      >
        <i class="icofont icofont-eye" />
      </router-link> -->
    <a
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="canDelete"
    ><i class="icofont icofont-close" /></a>
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
  import { DELETE_PRONOSTIC } from '../../../graphql/sanitaire';
  export default {
      props : {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          deleting(){
              this.$apollo.mutate({
                  mutation: DELETE_PRONOSTIC,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Pronostic ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting pronostic ${this.object.uid}`)
                  }
                })
          }
      },
      computed: {
          ...mapGetters({
            is_super_admin: 'auth/is_super_admin',
            is_veterinaire: 'auth/is_veterinaire',
          }),
          canDelete(){
            return this.is_super_admin ||
              this.is_veterinaire
          }
      }
  
  }
  </script>
  
  <style>
  
  </style>